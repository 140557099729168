<template>
  <div>
    <div class="box">
      <div class="leftList">
        <div :class="item.index == activeIndex ? 'leftInfo active':'leftInfo'" v-for="(item,index) in leftList" :key="index" @click="activeChoose(item)">
          <span class="title">{{item.title}}</span>
        </div>
      </div>
      <div class="rightList">
        <div class="right_title">
         <div class="right_info_title"> {{rightList[this.activeIndex].title}}</div>
          <div class="time">
            <div>日期：{{rightList[this.activeIndex].time}} </div>
            <div style="margin-left: 14px">浏览数：{{rightList[this.activeIndex].num}}</div>
          </div>
        </div>
        <div>
          <div v-html="formattedText"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "info",
  computed: {
    formattedText() {
      // 将换行符转换为HTML的换行标签<br>
      return this.rightList[this.activeIndex].text.replace(/\n/g, "<br>");
    },
  },
  data(){
    return{
      leftList:[
        {index:0,title:'狱政管理科'},
        {index:1,title:'刑罚执行科'},
        {index:2,title:'教育改造与心理矫正科'},
        {index:3,title:'生活卫生科'},
        {index:4,title:'新入监告科'},
        {index:5,title:'活动视频'},
      ],
      activeIndex:0,
      rightList:[
        {index:0,title:'狱政管理科',time:'2021-01-22',num:99,text:'狱政管理，是监狱对罪犯实施监管和行政管理活动的总称。狱政管理是监狱工作的重要组成部分，贯穿于惩罚和改造罪犯的过程，渗透在监狱工作的各个方面。狱政管理的内容十分广泛，是一项政策性和法律性很强，而且非常具体和复杂的工作。\n' +
              '一、狱政管理概念的要素\n' +
              '（一）狱政管理的实质是一种刑事司法管理工作\n' +
              '国家的刑事司法活动包括侦查、起诉、审判和执行等环节，前三个环节一般围绕是否追究犯罪嫌疑人、被告人的刑事责任而展开，而执行环节则重在对罪犯进行管理，因而称之为刑事司法管理工作。\n' +
              '刑事执行阶段的刑事司法管理工作主要涉及监狱和公安机关。监狱是国家的刑罚执行机关，担负着对被判处死刑缓期二年执行、无期徒刑、有期徒刑罪犯的管理工作。公安机关则主要负责拘役、管制、有期徒刑同时宣告缓刑罪犯的管理，对假释和暂予监外执行的罪犯的监督，并由看守所代为执行交付执行时剩余刑期不足三个月的罪犯的管理工作。\n' +
              '（二）狱政管理工作的基本管理关系是监狱依法对在押服刑罪犯的管理。监狱是狱政管理活动的实际管理者，而这一管理活动的具体操作者则是监狱人民警察。狱政管理的基本管理对象是正在监狱服刑改造的被判处死刑缓期二年执行、无期徒刑、有期徒刑的罪犯。\n' +
              '（三）狱政管理的基本内容主要体现在监管改造和罪犯处遇等方面\n' +
              '1.监管改造\n' +
              '监管改造是指监狱对罪犯的各种监督、管制活动，这是最为繁重的狱政管理内容。监狱对罪犯的监管，不仅为罪犯教育、罪犯劳动提供了前提和保障，其本身也是具有改造功能的活动。\n' +
              '2.罪犯处遇\n' +
              '罪犯处遇是指监狱依法对罪犯服刑期间的各种待遇问题的处置。罪犯待遇是监狱活动的基本问题，其实质是罪犯权利的处置问题。监狱既要依法剥夺罪犯的人身自由，依法限制罪犯的一部分权利，又要依法保障罪犯的基本人权及各种合法权益，还要通过分级处遇调动罪犯的服刑改造的内驱力，发挥处遇管理的改造功能。\n' +
              '（四）狱政管理的根本要求是依法实施管理\n' +
              '狱政管理必须依法实施，这是狱政管理的根本要求。狱政管理的法律依据有以下四个层次：\n' +
              '1.宪法和基本法律\n' +
              '我国《宪法》第二十八条明确规定，国家惩办和改造犯罪分子。这一规定是监狱实施管理的根本性的法律依据。《刑法》和《刑事诉讼法》为狱政管理提供了基本法律依据。\n' +
              '2.法律\n' +
              '这里指全国人大常委会针对某一特殊领域制定的法律。作为狱政管理依据的特殊性法律，主要指《监狱法》、《人民警察法》、《国家赔偿法》等。\n' +
              '3.行政法规\n' +
              '这是由国务院制定的与监狱行刑有关的行政法规，有《监狱管理条例》(正在制定中)、《人民警察使用警械和武器条例》等。\n' +
              '4.行政规章\n' +
              '这里指国家司法部制定的有关监狱工作的行政规章。\n' +
              '除此之外，国家立法和司法机关的相关司法解释及规定，也是狱政管理的依据。\n' +
              '二、狱政管理的特征\n' +
              '狱政管理的特征可以概括为目的性、协调控制性、组织性、教育性和直接性五个方面。\n' +
              '（一）目的性\n' +
              '我国监狱的狱政管理是具有明确目的的管理活动。我国狱政管理的目的是完成《宪法》第二十八条规定的“惩办和改造犯罪分子”的任务，正确执行刑罚，惩罚和改造罪犯，预防和减少犯罪，实现将罪犯改造成为守法公民的总体目标。狱政管理的所有内容，都是为实现这个总体目标服务的。为此，我国监狱充分注重狱政管理的惩罚、改造和保障三项基本功能的发挥。\n' +
              '1.惩罚功能\n' +
              '监狱要通过刑罚的执行，完成国家对犯罪的惩罚任务。监狱对犯罪的惩罚体现在罪犯人身自由的依法剥夺，其基本方式就是对罪犯依法实施监禁，而监禁罪犯主要依靠狱政管理来完成。因此，狱政管理是监狱对罪犯实施刑罚处罚的主要手段。\n' +
              '2.改造功能\n' +
              '狱政管理与教育改造、劳动改造一起，形成我国监狱改造罪犯的三大基本手段。狱政管理的特殊改造功能，主要体现在惩戒威慑、规范约束、养成教育、保障感化、激励督导和组织过渡等方面。\n' +
              '3.保障功能\n' +
              '狱政管理是监狱的一项基础工作，对监狱各项执法和改造工作起着强有力的保障作用。（1）保证监管改造场所的安全，保障惩罚改造工作的顺利进行；（2）建立规范有序的监管改造环境，为教育改造和劳动改造的顺利实施奠定基础；（3）以合法的强制作为劳动、教育等改造手段的坚强后盾，保证这些手段的运作； （4）提供罪犯服刑改造生活上的物质保障，保障罪犯的合法权益。\n' +
              '（二）协调控制性\n' +
              '1.协调职能\n' +
              '我国的狱政管理是特殊的协调控制工作。协调是狱政管理的一项重要的职能：（1）对各种改造手段的协调，使之形成合力，避免出现互相牵制或者争夺时间空间等不和谐现象；（2）对监狱行刑要素的协调，通过对监狱执行程序、监管时空、罪犯权利义务以及待遇处置等要素的协调，把众多要素整合为一个有机的整体；（3）对刑罚执行过程的协调，使刑罚的执行更富有弹性、更富有激励作用，从而具有更强烈的改造功能。\n' +
              '2.控制职能\n' +
              '控制是狱政管理的又一项重要职能。监狱控制是整个监狱活动的重要问题，狱政管理在监狱控制系统中担当着重要的角色。我国监狱工作坚持“大狱政”的观念，强调狱政管理工作要“管到边，管到沿”，消除“管理死角”。这就意味着必须将罪犯服刑期间的各个活动的空间和时间纳入狱政管理的范围，实施全面监管和控制，确保监管安全，并充分发挥管理在改造罪犯中的积极作用。\n' +
              '（三）组织性\n' +
              '为保证刑罚目的的实现和改造罪犯的完成，必须对服刑罪犯实施严密的组织管理。我国监狱逐步形成了“内在式”的监管组织特色。监狱内的基本押犯单位，包括监区、分监区和分队，是一个混编的集体，管理人员与被管理者，即监狱人民警察和服刑罪犯都居其中，管理人员直接置身于罪犯正式组织之中，并且担任这些组织的行政领导，同时还配置有专职的政治思想工作人员。这种组织形式，便于监狱人民警察直接领导和控制着服刑改造组织，并用集体的力量约束和改造罪犯，坚持集体主义价值导向，培养集体主义精神，建立良好的狱内人际关系。我国监狱还注重罪犯非正式组织的管理、控制和引导。\n' +
              '根据分押、分管、分教的要求，将罪犯组织成为有利于改造的群体，使罪犯的生活、劳动、学习都在有组织、有纪律的集体中进行。通过建立罪犯自我管理的形式，形成罪犯之间相互监督、相互促进、相互协作、相互帮助、共同改造的局面。同时，分化瓦解狱内的各种不良的非正式群体，打击牢头狱霸，防止罪犯之间的传习教唆活动。\n' +
              '（四）教育性\n' +
              '我国的狱政管理是具有特殊教育功能的工作。（1）我国的狱政管理充分体现人道主义精神，依法保障罪犯的合法权益。从改造罪犯成为新人这一根本人权利益出发，本身就具有威力很强的感召力和教育作用。（2）我国的狱政管理始终贯彻管教结合的原则，寓教于管，管中有教，以理服人。（3）我国的狱政管理实行严格的规范化管理，通过行为规范和考核奖惩，使管理活动具有强烈的教育功能，使服刑罪犯从强制遵守到自觉遵守的过程中，逐步养成良好的生活习惯和行为习惯。\n' +
              '（五）直接性\n' +
              '为保证安全、正确、有效地实施狱政管理，并且保证国家独有的行刑权不被转手他人，我国监狱坚持监狱人民警察对服刑罪犯的直接管理。在人民警察与罪犯之间不能存在一个阻断层，必须坚决防范和打击牢头狱霸，防止牢头狱霸阻断和破坏正常的狱政管理关系。直接管理是监狱依法准确执行刑罚的保证，是保证监所安全和防范各种突发事件的需要，是教育改造罪犯的需要，是实现对罪犯依法、严格、科学、文明管理的实践基础，是狱政管理的重要特征之一。'},
        {index:1,title:'刑罚执行科',time:'2022-06-22',num:19,text:'一、刑事执行科有什么工作?\n' +
              '\n' +
              '负责应当由省人民检察院承担的死刑、监禁刑、社区矫正、资格刑、财产刑等刑罚执行监督工作，以及减刑、假释、暂予监外执行等刑罚执行变更监督工作，减刑、假释案件庭审活动;对刑罚执行单位、刑事强制措施执行机关、强制医疗执行单位监管活动和执法行为是否合法进行监督;承办发生在刑罚执行和监管活动中的影响较大的职务犯罪案件侦查，开展职务犯罪预防工作;对省人民检察院决定并交付公安机关执行的指定居所监视居住的执行进行监督;对被羁押人的羁押期限和司法机关办案期限是否合法进行监督，对继续羁押必要性进行审查并提出建议;对全省检察机关开展所有监所检察业务进行管理和指导，包括对拘留、逮捕等刑事强制措施执行监督，办理服刑人员又犯罪案件的审查批捕、审查起诉、出庭公诉工作，以及开展强制医疗执行监督等;处理服刑人员及其法定代理人、近亲属不服已经发生法律效力的刑事判决、裁定的申诉工作，以及与刑事执行相关的控告、举报和申诉;规划和总结全省监所检察工作，规范监所检察工作开展，答复下级人民检察院监所检察工作中重大疑难案件和问题的请示;承办检察长交办的其他事项。\n' +
              '\n' +
              '二、监狱的刑事执行科负责什么工作?\n' +
              '\n' +
              '1、依法执行刑罚;指导调处罪犯的申诉、控告、检举工作，负责罪犯加刑、狱内又犯罪和余漏罪处理等工作。\n' +
              '\n' +
              '2、负责罪犯减刑、假释工作及审核死缓、无期徒刑罪犯减刑呈报工作，负责罪犯暂予监外执行(保外就医)呈报工作。\n' +
              '\n' +
              '3、刑罚执行是指有行刑权的司法机关将人民法院生效的判决所确定的刑罚付诸实施的刑事司法活动，指执行刑罚应从实现特殊预防及一般预防的目的出发，对犯罪人及社会公众进行积极教育，而非消极的惩罚与威慑。\n' +
              '\n' +
              '刑事执行是国家对犯罪活动进行侦查审判的最后一道环节，在人民法院下达的判决书生效之后，进行刑事执行。在监狱中刑事执行科负责执行犯人的刑罚，犯人的减刑，假释等工作的审核。'},
        {index:2,title:'教育改造与心理矫正科',time:'2023-03-12',num:109,text:'监狱教育改造与心理矫正工作是监狱工作的重要组成部分，‌旨在通过教育和心理矫正手段帮助罪犯改过自新，‌重新融入社会。‌以下是对监狱教育改造与心理矫正工作的介绍：‌\n' +
              '\n' +
              '教育改造：‌监狱通过开展各种教育活动，‌如心理健康教育、‌团体心理辅导、‌个体心理咨询等形式，‌帮助罪犯提高自我认知，‌改善人际关系，‌掌握心理健康的调适方法，‌从而明确改造方向，‌树立改造的信心和勇气。‌这些活动旨在促进罪犯的心理健康，‌提高教育改造质量，‌降低回归社会后的重新犯罪率1。‌\n' +
              '\n' +
              '心理矫正：‌心理矫正工作通过专业的心理评估、‌心理测试与评估结果反馈、‌针对性的心理疏导和宣泄等活动，‌帮助罪犯缓解心理压力，‌提供心理支持。‌通过这些措施，‌可以找到问题发生的源头，‌提出建设性的指导意见，‌使罪犯心理分析更为精确，‌咨询方案更为精准，‌矫治效果更为突出1。‌\n' +
              '\n' +
              '专业团队与培训：‌为了提升教育改造与心理矫正工作的专业性和有效性，‌监狱配备了专业的心理咨询师和教育工作团队。‌这些团队成员通常具有心理学、‌教育学、‌医学等专业的背景，‌并通过专业的培训和技能考试，‌以确保他们能够提供高质量的心理矫正服务2。‌\n' +
              '\n' +
              '创新实践：‌一些监狱还开展了创新性的实践活动，‌如“心栏目”让罪犯面对镜头敞开心扉，‌分享改造生活中的心得；‌通过“阳光帮扶”“送法进监”等活动，‌强化帮教职能，‌延伸帮教触角，‌促进罪犯积极改造3。‌\n' +
              '\n' +
              '评估与监督：‌监狱通过全面的心理评估和监督机制，‌确保教育改造与心理矫正工作的质量和效果。‌这包括入监评估、‌中期评估和出监评估，‌以及通过“心理测试+心理面咨+罪犯自述+改造建议”的精准评估形式开展心理矫治，‌真正做到早发现、‌早介入、‌早矫正14。‌\n' +
              '\n' +
              '综上所述，‌监狱教育改造与心理矫正工作通过专业的团队、‌创新的方法和全面的评估机制，‌帮助罪犯重塑新生，‌降低重新犯罪率，‌促进社会和谐稳定。‌'},
        {index:3,title:'生活卫生科',time:'2024-02-22',num:66,text:'工作以后，时常会有同事问我，监狱里生活卫生民警是干嘛的？是不是每天带着一群人打扫卫生，打打杂什么的，我也不厌其烦的多次回答这个问题，我说：“监狱里生活卫生民警其实是监狱里的大管家，吃喝拉撒，甚至连呼吸的空气都属于他们的管辖氛围。”\n' +
              '\n' +
              '其实在每个监狱民警心里都有着不同的答案，我就简要的谈谈自从来到生活卫生岗位后，在我所认知里的监狱生活卫生民警。\n' +
              '\n' +
              '卫生防疫的先锋军——自疫情暴发以来，作为生活卫生民警，在一千多个日日夜夜里，如何精准掌握各项疫情防控措施，根据疫情动态不断调整各项疫情防控工作成为了他们的日常，从疫情初期的防疫物资筹备、生活物资采购、疫苗接种、体温检测、环境消杀等工作，到疫情暴发后各项精准的疫情防控处置，以及与地方卫健部门以及疫情防控部门的联防联控，到处都有着他们的身影。\n' +
              '\n' +
              '\n' +
              '\n' +
              '生活物资的采购员——小到一粒米大到我们所需的生活设施设备，监狱里所有的生活物资采购都离不开生活卫生民警，他们通过货比三家的询价，确保采购物品的价廉物美，通过细致的比对确保物品物超所值；他们通过细致的验收确保商家不偷奸耍滑，通过精准的农药残留检测确保大家吃得放心，通过严格执行的索票索证制度确保生活物资能够根清源正，他们是眼里容不了假的“铁面包公”。\n' +
              '\n' +
              '\n' +
              '\n' +
              '生活卫生的监督员——为了给所有人一个整洁的居住环境，他们化身生活卫生监督员，重复着“鸡蛋里挑骨头”的工作，只为培养大家良好的卫生习惯，减少疾病的产生，变相的给自己“减压”。\n' +
              '\n' +
              '生活设施的维修员——他们每天接到最多的电话就是：“小张，你快过来看看我们这里没热水了。”，“小王，你快来看看我这下水道堵了，现在臭得不得了你赶紧过来处理”，“小赵，我这卫生工具没有了赶紧买一点”，“小李，今天生活垃圾有点多，明天你记得垃圾车多来两趟”，工作的这些年，我从一个连电灯泡都换不了的小青年，横向发展到了电工、焊工、维修工、粉刷匠于一身的专业技术人员。\n' +
              '\n' +
              '\n' +
              '\n' +
              '食品安全的宣传员——他们通过食品安全宣传周以及平时的食品安全检查，不断地去完善食品安全中的薄弱环节，通过食品安全培训，让更多的人了解食品安全，重视食品安全，宣传食品安全。\n' +
              '\n' +
              '乡村振兴的推行者——他们通过实施乡村振兴“五大行动”，不断地巩固着巩固脱贫攻坚成果，从产业帮扶到技术培训，持续推动同乡村振兴战略有机衔接，让脱贫群众生活更上一层楼。\n' +
              '\n' +
              '\n' +
              '\n' +
              '以上这些只是他们工作的一部分，最重要的是他们始终与所有奋斗在监管一线的民警一样，用最崇高的工作热忱、最强的责任意识、最严的工作纪律、最实的工作作风，细致入微的守护他们最爱的监狱事业。'},
        {index:4,title:'新入监告科',time:'2023-11-15',num:6,text:'“喂，我的家人刚到监狱服刑，\n' +
              '\n' +
              '他现在是什么情况？”\n' +
              '\n' +
              '“你好，入监之后要教他们做什么吗？”\n' +
              '\n' +
              '“请问某某某，要分到哪个监区？”\n' +
              '\n' +
              '今天木兰姐姐就来说说\n' +
              '\n' +
              '罪犯奔向新生的“第一课”\n' +
              '\n' +
              '——\n' +
              '\n' +
              '【入监教育考核验收】\n' +
              '\n' +
              '\n' +
              '\n' +
              '\n' +
              '\n' +
              '<img data-index="1" src="https://imagepphcloud.thepaper.cn/pph/image/200/328/772.jpg" style="height: 400px">'+
              '新生从这里起步！\n' +
              '\n' +
              '要想了解什么是入监教育考核验收，有一个重要概念不容忽视！那就是【入监教育】。\n' +
              '\n' +
              '\n' +
              '\n' +
              '入监教育是监狱教育改造的第一个工作环节，是针对新入监的罪犯法律意识淡薄、权利义务模糊，身份意识不确定、对监狱改造生活不适应而设定的阶段性教育，是监狱改造工作的起始环节。\n' +
              '\n' +
              '新入监的罪犯（后简称，新犯）\n' +
              '\n' +
              '<img data-index="2" src="https://imagepphcloud.thepaper.cn/pph/image/200/328/776.jpg" style="height: 500px">'+
              '在完成两个月的入监教育后\n' +
              '\n' +
              '就要参加由教育改造部门组织的\n' +
              '\n' +
              '入监教育考核验收\n' +
              '\n' +
              '只有验收合格\n' +
              '\n' +
              '才能分流到各监区\n' +
              '\n' +
              '开启下一阶段的改造\n' +
              '\n' +
              '\n' +
              '\n' +
              '入监教育考核现场\n' +
              '\n' +
              '因此对于新犯而言\n' +
              '\n' +
              '入监教育考核验收\n' +
              '\n' +
              '是改造的起点\n' +
              '\n' +
              '也意味着新生的机会\n' +
              '\n' +
              '\n' +
              '\n' +
              '考核现场大揭秘！\n' +

              '\n' +
              '面对“灵魂拷问”\n' +
              '\n' +
              '她受得住么？\n' +
              '\n' +
              'PART.01\n' +
              '\n' +
              '“\n' +
              '\n' +
              '请背诵《行为规范》第十条内容。”\n' +
              '\n' +
              '“\n' +
              '\n' +
              '请回答，如果行进中路遇警官，你要怎样向警官打报告词。”\n' +
              '\n' +
              '考核验收现场\n' +
              '\n' +
              '由教育改造、刑罚执行、狱政管理等职能科室\n' +
              '\n' +
              '组成的考官团\n' +
              '\n' +
              '通过现场问答的形式\n' +
              '\n' +
              '对新犯发起“灵魂拷问”\n' +
              '\n' +
              '——\n' +
              '\n' +
              '\n' +
              '\n' +
              '考官通过随机考核新犯对《罪犯行为规范》、《罪犯二十条严禁行为》、报告词等服刑改造应知应会内容的熟练程度，检验其对身份意识、监狱环境、正确改造观的认同程度。\n' +
              '\n' +
              '\n' +
              '\n' +
              '熟练记背服刑改造应知应会内容，是新犯顺利完成入监教育的“基本功”，也是入监教育考核验收现场需要过的第一关！\n' +
              '\n' +
              '只有在思想上完成转换，真正认同自己是一名罪犯，才会主动适应监狱的环境要求，尽快进入角色，主动接受入监教育，服管服教，为今后的自觉改造打好基础。\n' +
              '\n' +
              'PART.02\n' +
              '\n' +
              '关于“知行合一”\n' +
              '\n' +
              '她的表现是？\n' +
              '\n' +
              '如果说应知应会是“基本功”\n' +
              '\n' +
              '那么将基本功内化于心、外化于行\n' +
              '\n' +
              '就显得非常重要\n' +
              '\n' +
              '因此“新生第一考”第二关\n' +
              '\n' +
              '考的便是新犯的行为调整关！\n' +
              '\n' +
              '\n' +
              '\n' +
              'SUMMER SUGER\n' +
              '\n' +
              '++++\n' +
              '\n' +
              '考核现场，新犯需在指挥员的口令下完成停止间转法、队列行进等一整套规范的队列动作，做到令行禁止。\n' +
              '\n' +
              '\n' +
              '\n' +
              '队列训练是入监教育的重要课程之一，它看似枯燥，却对助推罪犯纪律意识的养成和强化有关键作用。\n' +
              '\n' +
              '从端正言行开始，让自己的一言一行、一举一动都自觉接受监规纪律的约束，反思犯罪教训，痛下决心矫治恶习，才能实现重塑自我。\n' +
              '\n' +
              '新生起步\n' +
              '\n' +
              '从“叠标被”开始！\n' +
              '\n' +
              'PART.03\n' +
              '\n' +
              '\n' +
              '\n' +
              '压、搓、切、按、修……\n' +
              '\n' +
              '在叠标被考核中，新犯听到口令后，需动作迅速，在规定时间内完成棱角分明、大小一致的标被整理。\n' +
              '\n' +
              '\n' +
              '\n' +
              '看到这里，想必不少人会产生疑问，“折铺盖”也能成为考试科目？\n' +
              '\n' +
              '没错！看似日常又简单的叠标被\n' +
              '\n' +
              '其背后是新犯对监狱教育管理的服从度\n' +
              '\n' +
              '是对正确劳动观的认同度\n' +
              '\n' +
              '被子“事小”\n' +
              '\n' +
              '审视的是其背后的服刑改造态度\n' +
              '\n' +
              '\n' +
              '\n' +
              '\n' +
              '\n' +
              '要知道\n' +
              '\n' +
              '入监教育重在学，但更重在做\n' +
              '\n' +
              '只有将学到的身份意识、纪律意识、价值观念\n' +
              '\n' +
              '融入日常，指导自己的改造实践\n' +
              '\n' +
              '以积极健康向上的姿态适应改造生活\n' +
              '\n' +
              '才能更快更好走向新生\n' +
              '\n' +
              '\n' +
              '\n' +
              '当然，除了上述现场考核验收三大科目以外，入监教育考核验收其实还有其他严格周密的验收程序和标准，如，以文化教育、安全常识、岗位技能、心理健康等为内容的平时考核验收，也是完成新犯入监教育考核验收的重要参考之一。\n' +
              '\n' +
              '入\n' +
              '\n' +
              '监\n' +
              '\n' +
              '教\n' +
              '\n' +
              '育\n' +
              '\n' +
              '\n' +
              '\n' +
              '一切从这里结束\n' +
              '\n' +
              '一切从这里开始\n' +
              '\n' +
              '入监教育考核验收\n' +
              '\n' +
              '于罪犯服刑改造而言\n' +
              '\n' +
              '是场货真价实的“新生礼”\n' +
              '\n' +
              '——\n' +
              '\n' +
              '罪犯从这时起告别过去的自己\n' +
              '\n' +
              '接受身份转变\n' +
              '\n' +
              '这也是她们迈向新生的第一个关口\n' +
              '\n' +
              '改造自己的过程或许很痛苦\n' +
              '\n' +
              '但是\n' +
              '\n' +
              '只有重拾诚心、下定决心\n' +
              '\n' +
              '向上向善、奋力奔跑\n' +
              '\n' +
              '才能尽快回归家庭、回报社会\n' +
              '\n' +
              '拥有美好的未来'},
        {index:5,title:'活动视频',time:'2022-10-15',num:122,text:'<video loop="loop" controls autoplay="autoplay" preload="auto" type="video/mp4" src="http://1400289574.vod2.myqcloud.com/d4450852vodtranscq1400289574/e2ee82b5387702299324658311/v.f30.mp4" style="height: 400px"></video>' +
              '\n他们是谁？\n' +
              '\n' +
              '他们要去哪里？\n' +
              '\n' +
              '他们在追寻什么？\n' +
              '\n' +
              '他们是疫情来临，主动请战的医者仁心\n' +
              '\n' +
              '他们是洪魔突至，挺身而出的责任担当\n' +
              '\n' +
              '他们是忠肝义胆，惩恶扬善的春风化雨\n' +
              '\n' +
              '他们是坚守高墙，守护万家灯火的那抹藏蓝\n' +
              '\n' +
              '每一个平凡的他们，都是平安中国的守护者\n' +
              '\n' +
              '步履不停，征途漫漫\n' +
              '\n' +
              '警心向党，初心如磐\n' +
              '\n' +
              '捍卫法治，守护平安\n' +
              '\n' +
              '因为平凡，所以不凡\n' +
              '\n' +
              '守护国家安全，他们永远在路上！'},
      ]
  }
  },
  created(){
    this.activeIndex = this.$route.params.id
    console.log()
  },
  methods:{
    activeChoose(item){
      this.activeIndex = item.index
    }
  }
}
</script>

<style scoped lang="scss">
.box{
  padding: 40px;
  margin: 0 auto;
  width: 1200px;
  display: flex;
  justify-content: space-between;
}
.leftList{
  display: flex;
  flex-direction: column;
  width: 27%;

  .leftInfo{
    background: #E7E7E7;
    padding: 20px 20px 20px 40px;
    font-size: 20px;
    color: black;
    border-bottom: 1px solid #D4D4D4;
  }
  .title:hover{
    cursor: pointer;
    color: red;
  }
  .active{
    background: #1B69B3;
    color: white;
  }
}
.rightList{
  width: 70%;
  .right_title{
    text-align: center;
    margin-bottom: 30px;
    .right_info_title{
      font-size: 26px;
      margin-bottom: 10px;
    }
    .time{
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
